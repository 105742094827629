import transactionsService from "../../../../services/transactionsService";
import $formatNumber from "../../../../mixins/formatNumber";

export default {
    name: "failed-evacuation",
    components: {},
    props: [],
    mixins: [$formatNumber],
    data() {
        return {
            evacuation: "",
            refIdSearch: null,
            OrderIdSearch: null,
            currentPage: 1,
            tableHeaders: [
                "نام شرکت",
                "ارز پرداختی",
                "ارز دریافتی",
                "کد پیگیری بیت‌راه",
                "کد پیگیری فروشگاه",
                "عملیات",
            ],
        };
    },
    computed: {},
    mounted() {
        this.getFailedEvacuationFunc();
    },
    methods: {
        getFailedEvacuationFunc() {
            transactionsService
                .getFailedEvacuation(
                    0,
                    localStorage.getItem("bitrahAdminAccessToken")
                )
                .then((response) => {
                    this.evacuation = response.data;
                });
        },
        sendAgain(data, index) {
            transactionsService
                .resendEvacuation(
                    data,
                    localStorage.getItem("bitrahAdminAccessToken")
                )
                .then((response) => {
                    if (response.data) {
                        this.evacuation.content.splice(index, 1);
                        this.$bvToast.toast(this.$i18n.t("toast.succesfullOperation"), {
                            title: this.$i18n.t("toast.successTitle"),
                            noCloseButton: true,
                            variant: "custom",
                            bodyClass:
                                "successFontColor rtl text-right font-weight-bold py-3",
                            headerClass:
                                "successBackgroundColor successFontColor  rtl text-right",
                            solid: false,
                        });
                    } else {
                        this.$bvToast.toast(this.$i18n.t("toast.failedOperation"), {
                            title: this.$i18n.t("toast.errorTitle"),
                            noCloseButton: true,
                            variant: "custom",
                            bodyClass:
                                "dangerBackgroundColor dangerFontColor rtl text-right",
                            headerClass:
                                "dangerBackgroundColor dangerFontColor  rtl text-right",
                            solid: false,
                        });
                    }
                });
        },
        submitFilterFailedEvacuation() {
            if (this.refIdSearch === "") {
                this.refIdSearch = null;
            }
            if (this.orderIdSearch === "") {
                this.orderIdSearch = null;
            }
            transactionsService
                .getFailedEvacuation(
                    0,
                    localStorage.getItem("bitrahAdminAccessToken"),
                    this.refIdSearch,
                    this.orderIdSearch
                )
                .then((response) => {
                    this.currentPage = 1;
                    this.evacuation = response.data;
                })
                .catch((error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                });
        },
        getPaginationData(id) {
            transactionsService
                .getFailedEvacuation(
                    id - 1,
                    localStorage.getItem("bitrahAdminAccessToken"),
                    this.refIdSearch,
                    this.orderIdSearch
                )
                .then((response) => {
                    this.evacuation = response.data;
                })
                .catch((error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                });
        },
    },
};
